import React from 'react'
import StoreProvider from '../contexts/storeProvider'

const Layout = ({children}) => {

  return (
    <StoreProvider>
      {children}
    </StoreProvider>
  )
}

export default Layout