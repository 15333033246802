/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "fgrApi",
            "endpoint": process.env.GATSBY_AMPLIFY_ENDPOINT,
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": process.env.GATSBY_AMPLIFY_IDENTITY_POOL_ID,
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": process.env.GATSBY_AMPLIFY_USER_POOLS_ID,
    "aws_user_pools_web_client_id": process.env.GATSBY_AMPLIFY_USER_POOLS_CLIENT_ID,
    "oauth": {}
};


export default awsmobile;
