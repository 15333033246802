/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
/* eslint-disable import/prefer-default-export, react/prop-types, consistent-return */
// import React from 'react';
// import {
//   getSessionPassword,
//   getQueryPassword,
// } from './src/components/passwordProtect/utils';
// import PasswordProtect from './src/components/passwordProtect/passwordProtect';
import Amplify from 'aws-amplify';
import awsExports from './src/aws-exports'
import { releaseBody } from './src/utils/helpers'

Amplify.configure(awsExports)

export const onRouteUpdate = ({ location }) => {
  if (typeof window !== 'undefined') {
    releaseBody();
  }
}

// export const wrapPageElement = ({ props }) => {
//   const { location } = props;
//   const password = process.env.GATSBY_PREVIEW_PASSWORD

//   // password protection disabled
//   if (!password) {
//     return;
//   }

//   // correct password
//   const passwordCandidate = getQueryPassword(location) || getSessionPassword();
//   if (passwordCandidate === password) {
//     return;
//   }

//   // check password
//   return <PasswordProtect />;
// };
