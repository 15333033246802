export const responsive = (mobile, desktop, steps = 6) => {
  return [...Array(steps)].map((x,i) => {
    if (i === 0) 
      return mobile
    if (i === steps-1)
      return desktop
    return null
  })
}

export const relDiff = (a, b) => {
  return 100 * Math.abs( ( a - b ) / ( (a+b)/2 ) );
}

export const percentage = (a,b) => {
  return (a/b) * 100
}

export const lockBody = () => {
  document.body.classList.add('no-scroll');
};

export const releaseBody = () => {
  document.body.classList.remove('no-scroll');
};

export const chunk = (array, chunkSize) => {
  return [].concat.apply([],
    array.map(function(elem, i) {
      return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
    })
  );
}

export const sanitise = (string) => {
  const trimmed = String(string).trim()
  return trimmed
}
